import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
   

   <path d="M1901 2306 c-19 -23 -6 -33 52 -39 70 -7 123 -34 174 -90 39 -42 73
-121 73 -168 0 -25 19 -34 37 -16 13 13 13 21 -1 76 -18 70 -60 138 -109 175
-73 56 -202 91 -226 62z"/>
<path d="M1904 2196 c-9 -23 2 -33 45 -39 22 -3 54 -15 71 -27 32 -23 70 -86
70 -118 0 -10 4 -22 10 -28 6 -6 -4 -8 -29 -6 -31 3 -38 7 -34 22 7 27 -23 78
-57 95 -38 20 -60 19 -76 -2 -12 -16 -13 -11 -14 35 l0 52 -490 0 c-477 0
-491 -1 -510 -20 -20 -20 -20 -33 -20 -613 0 -563 1 -595 18 -610 17 -15 74
-17 614 -17 566 0 596 1 612 18 15 17 16 64 14 535 -2 285 0 517 5 517 24 0
-10 96 -50 141 -43 47 -73 64 -130 74 -34 5 -44 4 -49 -9z m-54 -66 c8 -5 11
-13 6 -17 -4 -4 -201 -8 -438 -8 l-430 0 -23 -27 -23 -27 4 -505 c2 -324 7
-512 13 -525 16 -28 51 -31 401 -30 173 0 391 0 484 -1 159 -2 170 0 190 19
21 21 21 29 24 464 3 396 7 475 27 456 10 -11 6 -938 -4 -957 -10 -19 -30 -19
-573 -21 -484 -1 -565 1 -585 14 l-23 15 3 570 c2 487 4 572 17 580 19 12 911
12 930 0z m119 -84 c12 -11 21 -28 21 -38 0 -10 7 -21 15 -24 13 -5 15 -68 15
-483 l0 -476 -520 0 -520 0 0 523 0 523 484 -3 c460 -3 485 -4 505 -22z"/>
<path d="M1901 2006 c-9 -11 -8 -18 5 -31 15 -15 19 -15 32 -1 17 17 10 46
-11 46 -8 0 -20 -6 -26 -14z"/>
<path d="M1666 1748 c4 -13 6 -39 4 -58 -5 -67 -3 -201 4 -207 3 -4 30 1 60
10 29 9 57 17 62 17 5 0 9 -22 9 -48 l0 -48 -343 1 -342 0 0 -32 0 -33 370 0
370 0 0 119 0 119 -59 -18 c-32 -10 -64 -21 -70 -24 -8 -5 -11 19 -11 78 l0
86 40 0 c29 0 40 -4 40 -15 0 -10 10 -15 30 -15 29 0 30 2 30 45 l0 45 -101 0
c-100 0 -100 0 -93 -22z"/>
<path d="M1175 1595 l0 -155 54 0 54 0 -7 73 c-8 81 -11 81 63 -12 l51 -64 0
158 c0 87 -3 155 -7 152 -3 -4 -5 -36 -4 -72 1 -36 0 -65 -2 -65 -3 0 -31 32
-62 70 -54 66 -60 70 -99 70 l-41 0 0 -155z m124 49 c40 -41 61 -71 57 -80 -5
-13 -12 -11 -39 10 -31 24 -35 24 -55 10 -16 -11 -22 -25 -22 -55 0 -28 -4
-39 -15 -39 -12 0 -15 18 -15 103 0 97 3 117 20 117 3 0 35 -29 69 -66z"/>
<path d="M1408 1590 l3 -150 125 0 124 0 0 35 0 35 -81 0 c-78 0 -80 1 -77 23
3 21 7 22 71 19 l67 -4 0 41 0 41 -70 0 c-63 0 -70 2 -70 20 0 18 7 20 75 20
l75 0 0 35 0 35 -122 0 -123 0 3 -150z m74 81 c-16 -29 -16 -31 6 -54 l22 -24
-22 -28 c-21 -26 -21 -29 -5 -56 17 -31 9 -44 -23 -34 -18 6 -20 15 -19 108 1
56 3 105 6 110 2 4 15 7 28 7 24 0 24 0 7 -29z"/>
<path d="M962 813 c296 -2 780 -2 1076 0 295 1 53 2 -538 2 -591 0 -833 -1
-538 -2z"/>
<path d="M610 675 c0 -58 2 -65 20 -65 16 0 20 7 20 32 l0 33 23 -32 c12 -18
30 -33 40 -33 14 0 17 10 17 65 0 58 -2 65 -20 65 -16 0 -20 -7 -20 -32 l0
-33 -22 32 c-13 18 -31 33 -40 33 -15 0 -18 -10 -18 -65z"/>
<path d="M783 683 c-13 -32 -27 -61 -30 -65 -2 -5 7 -8 21 -8 16 0 26 6 26 15
0 9 9 15 25 15 14 0 28 -7 31 -15 6 -16 44 -21 44 -6 0 5 -12 34 -26 65 -35
76 -56 76 -91 -1z"/>
<path d="M927 678 c1 -56 4 -63 22 -66 19 -3 21 1 21 62 0 62 -1 66 -22 66
-22 0 -23 -4 -21 -62z"/>
<path d="M1000 725 c0 -9 9 -15 25 -15 21 0 25 -5 25 -31 0 -26 -3 -30 -20
-26 -26 7 -50 -21 -29 -34 46 -29 89 8 89 78 l0 43 -45 0 c-33 0 -45 -4 -45
-15z"/>
<path d="M1135 684 c-14 -31 -25 -60 -25 -65 0 -15 38 -10 44 6 3 8 15 15 26
15 11 0 23 -7 26 -15 4 -8 17 -15 29 -15 l24 0 -29 63 c-38 86 -61 88 -95 11z"/>
<path d="M1350 675 l0 -65 55 0 c42 0 55 3 55 15 0 10 -11 15 -35 15 -19 0
-35 5 -35 10 0 6 14 10 30 10 20 0 30 5 30 15 0 10 -10 15 -30 15 -16 0 -30 5
-30 10 0 6 16 10 35 10 24 0 35 5 35 15 0 12 -13 15 -55 15 l-55 0 0 -65z"/>
<path d="M1505 684 c-14 -31 -25 -60 -25 -65 0 -15 38 -10 44 6 3 8 15 15 26
15 11 0 23 -7 26 -15 6 -16 44 -21 44 -6 0 5 -12 34 -26 65 -34 75 -56 75 -89
0z"/>
<path d="M1650 725 c-17 -21 -5 -44 30 -56 39 -14 39 -30 -1 -27 -44 4 -49
-19 -6 -27 45 -9 77 8 77 41 0 19 -6 26 -30 31 -42 8 -52 27 -11 21 25 -4 31
-1 31 14 0 14 -8 18 -39 18 -22 0 -44 -6 -51 -15z"/>
<path d="M1766 728 c4 -7 16 -25 26 -40 10 -14 18 -38 18 -52 0 -19 5 -26 20
-26 15 0 20 7 20 28 0 15 11 44 25 65 l26 37 -25 0 c-16 0 -27 -7 -31 -20 -3
-11 -10 -20 -15 -20 -5 0 -12 9 -15 20 -4 13 -15 20 -31 20 -17 0 -23 -4 -18
-12z"/>
<path d="M2002 731 c-25 -15 -10 -53 26 -65 39 -14 32 -28 -9 -19 -22 4 -29 2
-29 -11 0 -36 92 -28 106 9 9 21 -14 45 -42 45 -13 0 -24 5 -24 11 0 7 10 9
24 7 22 -4 36 7 36 28 0 8 -75 4 -88 -5z"/>
<path d="M2122 688 c3 -45 7 -54 31 -68 24 -13 30 -13 55 0 23 14 27 23 30 68
3 47 1 52 -17 52 -18 0 -21 -6 -21 -45 0 -38 -3 -45 -20 -45 -17 0 -20 7 -20
45 0 39 -3 45 -21 45 -18 0 -20 -5 -17 -52z"/>
<path d="M2270 675 l0 -65 38 0 c71 0 107 24 82 55 -6 7 -8 20 -4 29 11 28
-15 46 -67 46 l-49 0 0 -65z m81 -20 c0 -5 -9 -11 -21 -13 -20 -3 -27 8 -13
22 10 9 34 3 34 -9z"/>
<path d="M1270 665 c0 -9 9 -15 25 -15 16 0 25 6 25 15 0 9 -9 15 -25 15 -16
0 -25 -6 -25 -15z"/>
<path d="M1910 665 c0 -9 9 -15 25 -15 16 0 25 6 25 15 0 9 -9 15 -25 15 -16
0 -25 -6 -25 -15z"/>
<path d="M435 570 c8 -13 2122 -13 2130 0 4 7 -368 10 -1065 10 -692 0 -1069
-3 -1065 -10z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
